import React, { useEffect } from "react"
import { isMobile } from "react-device-detect"
import Layout from "../layout/GeneralLayout"
import { Box } from "../components/commonElements"
import CoinSimu from "../components/coinSimu"
import StatsBoard from "../components/statsBoard"
import ContentBoard from "../components/contentBoard"
import ToolSettings from "../components/toolSettings"
import Footer from "../components/footer"
import styled from "styled-components"
import SEO from "../components/seo"
import ShareModal from "../components/modal/ShareModal"
import FileModal from "../components/modal/FileModal"
import { useSelector, useDispatch } from "react-redux"
import { getShareLink } from "../redux/shareLink/actions"
import Adthrive from "../components/adthrive"
import { useToastContext } from "../components/contextHook/toast"
import { usePageLoadingContext } from "../components/contextHook/pageLoading"
import { getPersistor } from "../utils/reduxPersistor"
import LogoProfile from "../images/tools/coin-image-big.png"
import queryString from "query-string"
import SettingsBoard from "../components/settingsBoard"

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.containerSize};
  display: grid;
  grid-template-columns: minmax(45rem, 100%) 30rem;
  /* ${props =>
    props.hideSettings
      ? `grid-template-columns: minmax(45rem, 100%) 5rem 30rem;`
      : `grid-template-columns: minmax(45rem, 100%) 34rem 30rem;`} */
  grid-template-rows: minmax(calc(100vh - 12rem), auto);
  grid-auto-rows: auto;
  grid-template-areas: "Tool RightSide"; //"Tool LeftSide RightSide";
  /* "LeftSide MiddleAd RightSide" */
  // "LeftSide Content RightSide";
  gap: 1rem 1rem;
  align-items: stretch;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  /* align-items: stretch;
  justify-content: space-between;
  flex-direction: row; */
  padding: 1rem 1rem;
  @media (min-width: 2000px) {
    grid-template-rows: minmax(87rem, auto);
  }
  @media ${props => props.theme.device.tablet} {
    // max-width: 90rem;
    grid-template-columns: minmax(45rem, 100%) 30rem;
    grid-template-rows: minmax(72rem, auto); //minmax(67rem, auto);
    grid-auto-rows: auto;
    grid-template-areas:
      "Tool RightSide"
      "MiddleAd RightSide"
      "LeftSide RightSide";
    // "LeftSide Content";
  }
  @media ${props => props.theme.device.mobile} {
    // max-width: 50rem;
    grid-template-columns: 100%;
    grid-template-rows: minmax(60rem, auto); //minmax(55rem, auto);
    grid-auto-rows: auto;
    grid-template-areas:
      "Tool"
      "MiddleAd"
      "LeftSide"
      "RightSide";
    // "Content";
    padding-bottom: 0rem;
  }
`

const RightSiderbar = styled(Box)`
  grid-area: RightSide;
  user-select: none;
  /* display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 1rem 1rem;
  margin-bottom: auto; */
  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`

// const SettingsHideBox = styled(GlassBox)`
//   cursor: pointer;
//   margin-bottom: 0rem;
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

// const ToolExpandCollapseBox = styled.div`
//   position: absolute;
//   right: 2rem;
//   bottom: 2rem;
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

// const SettingsShowBox = styled(GlassBox)`
//   ${props => !props.hideSettings && `display: none;`}
//   height: 100%;
//   // @media screen and (max-height: 840px) {
//   //   height: calc(100% - 16rem);
//   // }
//   cursor: pointer;
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

const LeftSidebar = styled(Box)`
  /* ${props => props.hideSettings && `display: none;`} */
  display: none;
  grid-area: LeftSide;
  grid-gap: 1rem 1rem;
  /* flex: 0 0 34rem; */
  /* width: 30rem; */
  /* @media ${props => props.theme.device.mobile} {
    order: 2;
    flex: 0 0 100%;
  } */
  & > div:nth-last-child(1) {
    margin-bottom: 0px;
  }
  // @media ${props => props.theme.device.tablet} {
  //   display: grid;
  //   grid-auto-flow: row;
  //   grid-template-columns: auto auto;
  //   margin-bottom: auto;
  //   grid-template-areas:
  //     "LeftSideSettings LeftSideLinkBox";
  // }
  // @media screen and (max-width: 920px) {
  //   grid-template-columns: 100%;
  //   grid-template-rows: auto;
  //   grid-auto-rows: auto;
  //   grid-template-areas:
  //     "LeftSideSettings"
  //     "LeftSideLinkBox";
  // }
  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
`

const LeftSideSettingsBox = styled(Box)`
  grid-area: LeftSideSettings;
  grid-gap: 1rem;
  margin: 0rem;
`

const ToolBox = styled(Box)`
  -webkit-tap-highlight-color: transparent;
  grid-area: Tool;
  z-index: 1;
  user-select: none;
  margin-bottom: 0rem;
  // max-height: 730px;
  // @media screen and (max-height: 840px) {
  //   max-height: 668.2px;
  //   margin-bottom: 16rem;
  // }
  /* background-color: white; */
  /* flex: 0 1 62rem; */
  /* min-height: 90vh; */
  /* justify-content: center; */
  /* @media ${props => props.theme.device.tablet} { */
  // max-height: 100%;
  // margin-bottom: 0rem;
  // width: 100vw;
  /* } */
  /* @media ${props => props.theme.device.mobile} {
    min-height: 70vh;
  } */
`

const MiddleAdBox = styled(Box)`
  grid-area: MiddleAd;
  display: none;
  user-select: none;
  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
`

const ContentBox = styled(Box)`
  font-size: 1.8rem;
  grid-area: Content;
  background-color: white;
  justify-content: center;
  /* flex: 0 0 63rem; */
  /* width: 40rem; */
  /* margin-top: 1rem; */
  /* padding-right: 1rem; */
  /* margin-left: auto; */
  /* @media ${props => props.theme.device.tablet} {
    margin-top: 1rem;
    flex: 0 0 100%;
  }
  @media ${props => props.theme.device.mobile} {
    order: 3;
  } */
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: ${props =>
    `minmax(45rem,${
      props.theme.containerSize.replace("rem", "") - 31
    }rem) 31rem`};
  @media ${props => props.theme.device.mobile} {
    grid-template-columns: auto;
  }
  padding-bottom: 7rem;
  padding-top: 3rem;
`

// const MobileHeaderAdBox = styled(Box)`
//   min-height: auto;
//   align-items: stretch;
//   justify-content: center;
//   margin-top: 0rem;
//   @media ${props => props.theme.device.tablet} {
//     min-height: 10rem;
//     margin-top: 1rem;
//   }
//   @media ${props => props.theme.device.mobile} {
//     min-height: 12.9rem;
//     margin-top: 1rem;
//   }
// `

// const LeftSidebarAd = styled(Adthrive)`
//   user-select: none;
//   /* max-height: 28rem; */
//   & > div {
//     margin: 0;
//   }
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

const RightSidebarAd = styled(Adthrive)`
  justify-content: flex-start;
  & > div {
    margin: 0;
  }
  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`
// const MobileHeaderAd = styled(Adthrive)`
//   display: none;
//   @media ${props => props.theme.device.tablet} {
//     min-height: 10rem;
//     & > div {
//       margin-bottom: 0px;
//     }
//     display: flex;
//   }
//   @media ${props => props.theme.device.mobile} {
//     min-height: 11rem;
//   }
// `

const MobileMiddleAd = styled(Adthrive)`
  @media ${props => props.theme.device.tablet} {
    min-height: 28rem;
    & > div {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`

// const ContentRightSidebarAd = styled(Adthrive)`
//   @media ${props => props.theme.device.mobile} {
//     display: none;
//   }
// `

// const StickyFooterBox = styled(Box)`
//   position: fixed;
//   bottom: 0; /* top: 0; for Top sticky */
//   left: 0;
//   width: 100%;
//   -webkit-overflow-scrolling: touch;
//   background-color: rgba(255, 255, 255, 0.8);
//   z-index: 1000;
//   height: 9rem;
// `

const IndexPage = props => {
  // const websiteMode = useSelector(state => state.common.websiteMode)
  // const intuitionResult = useSelector(state => state.modal.result)
  const shareModalOpened = useSelector(state => state.modal.shareModalOpened)
  const fileModalData = useSelector(state => state.modal.fileModalData)
  // const coinQuantityPersist = useSelector(state => state.settings.quantity.coin)
  // const [coinQuantity, setCoinQuantity] = useState(1)
  const { setToast } = useToastContext()
  const { setPageLoading } = usePageLoadingContext()
  /* const [hideSettings, setHideSettings] = useState(false) */
  const dispatch = useDispatch()
  // const location = useLocation()

  useEffect(() => {
    const search = props.location.search
      ? queryString.parse(props.location.search)
      : {}
    const shareId = search.id
    if (shareId) {
      getPersistor().pause()
      const data = {
        sharePage: "coin",
        sharePath: shareId,
      }
      setPageLoading(true)
      dispatch(getShareLink(data)).then(result => {
        setPageLoading(false)
        if (result.error) {
          // toast.warning(result.error)
          setToast(result.error)
        }
      })
    }
  }, [])

  // useEffect(() => {
  //   if (coinQuantityPersist !== undefined) {
  //     setCoinQuantity(coinQuantityPersist)
  //   } else {
  //     setCoinQuantity(1)
  //   }
  // }, [coinQuantityPersist])

  useEffect(() => {
    const handleResize = () => {
      document.getElementById("ToolRightSidebarAd").style.maxHeight = `calc(${
        document.getElementById("ToolContainer").offsetHeight +
        document.getElementById("ToolContentBox").offsetHeight
      }px - 1rem)`
    }
    window.addEventListener("resize", handleResize)
    handleResize()
  }, [])

  return (
    <>
      {/* <MobileHeaderAd
        id="MobileHeaderAd"
        alignItems="stretch"
        justifyContent="center"
      /> */}
      <Layout>
        <Container
          id="ToolContainer"
          /* hideSettings={hideSettings} */
        >
          {/* {typeof window !== "undefined" &&
        location.origin.includes("flipsimu.com") && ( //only call at the production
            <Helmet>
              <script
                async
                src="https://cdn.fuseplatform.net/publift/tags/2/2335/fuse.js"
              ></script>
            </Helmet>
          )} */}
          {shareModalOpened && <ShareModal />}
          {fileModalData && <FileModal />}
          <LeftSidebar
            gap="1rem"
            /* hideSettings={hideSettings} */
          >
            <LeftSideSettingsBox>
              <ToolSettings
                page="coin"
                initialState={isMobile ? true : false}
              />
              <StatsBoard />
            </LeftSideSettingsBox>
            {/* <ToolLinkBoardList /> */}
            {/* <SettingsHideBox
              alignItems="center"
              padding=".5rem"
              onClick={() => {
                setHideSettings(true)
              }}
            >
              <Tooltip content="Hide">
                <AngleDoubleRight size={22} />
              </Tooltip>
            </SettingsHideBox> */}
            {/* <CoinVideoAd /> */}
            {/* <LeftSidebarAd
              id="LeftSidebarAd"
              width="100%"
              alignItems="stretch"
              justifyContent="center"
              flex="1"
            /> */}
            {/* <StickySidebarAd
            id="22299337023"
            desktop
            alignSelf="stretch"
            flex="1"
            alignItems="center"
          />
          <PubliftAd
            id="22299337032"
            mobile
            tablet
            alignSelf="stretch"
            alignItems="center"
            justifyContent="center"
            minHeight={isMobile ? "28rem" : "auto"}
          /> */}
            {/* <FeedbackBoard /> */}
          </LeftSidebar>
          {/* <SettingsShowBox
            gap="1rem"
            padding="2rem 1rem"
            alignItems="center"
            justifyContent="space-between"
            hideSettings={hideSettings}
            onClick={() => {
              setHideSettings(false)
            }}
          >
            <Box gap="3rem">
              <Tooltip content="Customization">
                <Settings size={22} />
              </Tooltip>
              <Tooltip content="Global Stats">
                <Public size={22} />
              </Tooltip>
            </Box>
          </SettingsShowBox> */}
          <ToolBox gap="1rem" positionBox="relative">
            {/* {coinQuantity === 1 && <ChallengeBox />} */}
            {/* {coinQuantity === 1 && <ChallengeBoard />} */}
            <SettingsBoard page="coin" />
            <CoinSimu modeName="None" />
            {/* <ToolExpandCollapseBox>
              {hideSettings && (
                <Tooltip content="Collapse">
                  <IconButton
                    aria-label="Collapse"
                    onClick={() => {
                      setHideSettings(false)
                    }}
                    icon={<AngleDoubleLeft size={24} />}
                  />
                </Tooltip>
              )}
              {!hideSettings && (
                <Tooltip content="Expand">
                  <IconButton
                    aria-label="Expand"
                    onClick={() => {
                      setHideSettings(true)
                    }}
                    icon={<AngleDoubleRight size={24} />}
                  />
                </Tooltip>
              )}
            </ToolExpandCollapseBox> */}
          </ToolBox>
          <MiddleAdBox>
            <MobileMiddleAd
              id="MobileMiddleAd"
              width="100%"
              alignItems="stretch"
              justifyContent="center"
            />
          </MiddleAdBox>
          <RightSiderbar gap="1rem">
            <Box
              id="ToolRightSidebarAd"
              alignItems="center"
              maxWidth="30rem"
              width="100%"
              height="100%"
              positionBox="absolute"
              zIndex="99"
            >
              <RightSidebarAd
                id="RightSidebarAd"
                width="100%"
                height="100%"
                alignItems="stretch"
                justifyContent="center"
              />
            </Box>
            {/* <StickySidebarAd
            id="22299147479"
            desktop
            alignSelf="stretch"
            flex="1"
            alignItems="center"
          /> */}
          </RightSiderbar>
        </Container>
      </Layout>
      <ContentBox id="ToolContentBox" gap="1rem">
        <ContentBoard file="FlipSimuContent" />
        {/* <ContentRightSidebarAd
          id="ContentRightSidebarAd"
          width="100%"
          alignItems="stretch"
          justifyContent="center"
        /> */}
      </ContentBox>
      <Footer />
      {/* <StickyFooterAd
        id="22299147497"
        desktop
        alignItems="center"
      /> */}
      {/* <StickyFooterBox /> */}
      {/* <StickyFooterAd id="22299147485" mobile tablet alignItems="center" /> */}
    </>
  )
}

export default IndexPage

export const Head = () => (
  <SEO pathname="/" homepage={true} imageP={LogoProfile} />
)
